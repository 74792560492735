/*eslint-disable*/

import {
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentOutline,
  IoCashOutline,
} from 'react-icons/io5';
import Dashboard from '../pages/Dashboard';

// MASTER PAGE
import {
  Vendor,
  TambahVendor,
  UbahVendor,
  DetailVendor,
  PrakualifikasiVendor,
  UbahPrakualifikasiVendor,
  TambahPrakualifikasiVendor,
  CalonVendor,
  KualifikasiVendor,
  Kualifikasi,
  DetailKualifikasiVendor,
  HargaPerkiraanSendiri,
  ItemUpah,
  ItemSubcont,
  KelompokUpahSubcont,
} from '../pages/Master';

// TRANSAKSI PAGE
import {
  SeleksiVendor,
  ListPRSeleksiVendor,
  DetailSeleksiVendor,
  TambahSeleksiVendor,
  UbahSeleksiVendor,

  // purchase order
  PurchaseOrder,
  SeleksiVendorPurchaseOrder,
  DetailPurchaseOrder,
  TambahPurchaseOrder,
  UbahPurchaseOrder,
  ClosePurchasedOrder,

  // Permintaan Upah Produksi
  PermintaanUpahProduksi,
  DataPermintaanUpahProduksi,
  ListPermintaan,

  // Permintaan Subkon Produksi
  PermintaanSubkonProduksi,
  DataPermintaanSubkonProduksi,
  ListPermintaanSubkon,
  RegistrasiUpah,
  RegistrasiSubkon,
  TransferSubkonJobMix,
  ListPermintaanSubkonJobMix,
  TambahTransferSubkonJobMix,
  TransferUpahJobMix,
  ListPermintaanUpahJobMix,
  TambahTransferUpahJobMix,
  ClosePurchaseOrderList,
  PurchaseOrderList,
  ClosePurchaseOrderCreate,
  ClosePurchaseOrderDetail,

  //Transfer Upah Work Order
  ListTransferUpahWorkOrder,
  ListPermintaanUpahWorkOrder,
  TambahTransferUpahWorkOrder,

  //Transfer Subkon Work Order
  ListTransferSubkonWorkOrder,
  ListPermintaanSubkonWorkOrder,
  TambahTransferSubkonWorkOrder,
  SeleksiVendorAsetList,
  PermintaanAsetSeleksiVendorAsetList,
  SeleksivendorAsetCreate,
  PurchaseOrderAset,
  SeleksiVendorPurchaseOrderAset,
  TambahPurchaseOrderAset,
  DetailPurchaseOrderAset,
  ClosePurchaseOrderAsetList,
  PurchaseOrderAsetList,
  ClosePurchaseOrderAsetCreate,
  ClosePurchaseOrderAsetDetail,
  UbahPurchaseOrderAset,
  TransferUpahProduksiAsetList,
  TransferSubkonProduksiAsetList,
} from '../pages/Transaksi';
import { LaporanPurchaseOrder, LaporanPO } from '../pages/Laporan';
import Logo from '../assets/image/LogoPS.png';

export default {
  LOGO: Logo,
  MODUL: 'Procurement',

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: 'Dashboard',
      link: '/',
      type: 'menu',
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ['SUPA', 'PRO'],
    },
    {
      text: 'Master Data',
      type: 'dropdown',
      icon: <IoServerOutline />,
      hak: ['SUPA', 'PRO'],
      menu: [
        {
          text: 'Vendor',
          link: '/master/vendor',
          hak: ['SUPA', 'PRO_MAS_VEN'],
        },
        {
          text: 'Prakualifikasi Vendor',
          link: '/master/prakualifikasi-vendor',
          hak: ['SUPA', 'PRO_MAS_PKV'],
        },
        {
          text: 'Kualifikasi Vendor',
          link: '/master/kualifikasi-vendor',
          hak: ['SUPA', 'PRO_MAS_KV'],
        },
        {
          text: 'Harga Perkiraan Sendiri',
          link: '/master/harga-perkiraan-sendiri',
          hak: ['SUPA', 'PRO_MAS_HPS'],
        },
        {
          text: 'Item Upah',
          link: '/master/item-upah',
          hak: ['SUPA', 'PRO_REG_UPH'],
        },
        {
          text: 'Item Subcont',
          link: '/master/item-subcont',
          hak: ['SUPA', 'PRO_REG_SBC'],
        },
        {
          text: 'Kelompok Upah & Subcont',
          link: '/master/kelompok-upah-subcont',
          hak: ['SUPA', 'PRO_MAS_KUS'],
        },
      ],
    },
    {
      text: 'Transaksi',
      type: 'dropdown',
      icon: <IoCashOutline />,
      hak: [
        'SUPA',
        'PRO',
        'PRO_TRN_SV',
        'PRO_TRN_PO',
        'PRO_TRN_CPO',
        'PRO_TRN_TUP',
        'PRO_TRN_TSP',
        'PRO_TRN_PSP',
      ],
      menu: [
        {
          text: 'Seleksi Vendor',
          link: '/transaksi/seleksi-vendor',
          hak: ['SUPA', 'PRO_TRN_SV'],
        },
        {
          text: 'Purchase Order',
          link: '/transaksi/purchase-order',
          hak: ['SUPA', 'PRO_TRN_PO'],
        },
        {
          text: 'Close Purchase Order',
          link: '/transaksi/close-purchase-order',
          hak: ['SUPA', 'PRO_TRN_CPO'],
        },
        {
          text: 'Seleksi Vendor Aset',
          link: '/transaksi/seleksi-vendor-aset',
          hak: ['SUPA', 'PRO_TRN_SVA'],
        },
        {
          text: 'Purchase Order Aset',
          link: '/transaksi/purchase-order-aset',
          hak: ['SUPA', 'PRO_TRN_POA'],
        },
        {
          text: 'Close Purchase Order Aset',
          link: '/transaksi/close-purchase-order-aset',
          hak: ['SUPA', 'PRO_TRN_CPA'],
        },
        {
          text: 'Transfer Upah Produksi',
          link: '/transaksi/transfer-upah-job-mix',
          hak: ['SUPA', 'PRO_TRN_TUP'],
        },
        {
          text: 'Transfer Subkon Produksi',
          link: '/transaksi/transfer-subkon-job-mix',
          hak: ['SUPA', 'PRO_TRN_TSP'],
        },
        {
          text: 'Transfer Upah Work Order',
          link: '/transaksi/transfer-upah-work-order',
          hak: ['SUPA', 'PRO_TRN_TWU'],
        },
        {
          text: 'Transfer Subkon Work Order',
          link: '/transaksi/transfer-subkon-work-order',
          hak: ['SUPA', 'PRO_TRN_TWS'],
        },
        {
          text: 'Transfer Upah Produksi Aset',
          link: '/transaksi/transfer-upah-produksi-aset',
          hak: ['SUPA', 'PRO_TRN_TUPA'],
        },
        {
          text: 'Transfer Subkon Produksi Aset',
          link: '/transaksi/transfer-subkon-produksi-aset',
          hak: ['SUPA', 'PRO_TRN_TSPA'],
        },
      ],
    },
    {
      text: 'Laporan',
      type: 'dropdown',
      icon: <IoDocumentOutline />,
      hak: ['SUPA', 'PRO'],
      menu: [
        {
          text: 'Purchase Order',
          link: '/laporan/purchase-order',
          hak: ['SUPA', 'PRO_RPT_PO'],
        },
        {
          text: 'Purchase Order Vendor',
          link: '/laporan/purchase-order-vendor',
          hak: ['SUPA', 'PRO_RPT_POV'],
        },
      ],
    },
  ],

  ROUTES: [
    {
      exact: true,
      path: '/',
      page: Dashboard,
      hak: ['SUPA', 'PRO'],
    },

    //MASTER
    // VENDOR
    {
      exact: true,
      path: '/master/vendor',
      page: Vendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/vendor/detail/:id',
      page: DetailVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/vendor/tambah',
      page: TambahVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/vendor/:id',
      page: UbahVendor,
      hak: ['SUPA', 'PRO'],
    },

    // PRAKUALIFIKASI VENDOR
    {
      exact: true,
      path: '/master/prakualifikasi-vendor',
      page: PrakualifikasiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/prakualifikasi-vendor/calon-vendor',
      page: CalonVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/prakualifikasi-vendor/tambah/:id',
      page: TambahPrakualifikasiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/prakualifikasi-vendor/ubah/:id',
      page: UbahPrakualifikasiVendor,
      hak: ['SUPA', 'PRO'],
    },

    // HARGA PERKIRAAN SENDIRI
    {
      text: 'Harga Perkiraan Sendiri',
      path: '/master/harga-perkiraan-sendiri',
      page: HargaPerkiraanSendiri,
      hak: ['SUPA', 'PRO_MAS_HPS'],
    },

    // KUALIFIKASI VENDOR
    {
      exact: true,
      path: '/master/kualifikasi-vendor',
      page: KualifikasiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/kualifikasi-vendor/kualifikasi/:id',
      page: Kualifikasi,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/kualifikasi-vendor/detail/:id',
      page: DetailKualifikasiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/item-upah',
      page: ItemUpah,
      hak: ['SUPA', 'PRO_REG_UPH'],
    },
    {
      exact: true,
      path: '/master/item-subcont',
      page: ItemSubcont,
      hak: ['SUPA', 'PRO_REG_SBC'],
    },
    {
      exact: true,
      path: '/master/kelompok-upah-subcont',
      page: KelompokUpahSubcont,
      hak: ['SUPA', 'PRO_MAS_KUS'],
    },

    // TRANSAKSI
    // SELEKSI VENDOR
    {
      exact: true,
      path: '/transaksi/seleksi-vendor',
      page: SeleksiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/transaksi/seleksi-vendor/purchase-request',
      page: ListPRSeleksiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/transaksi/seleksi-vendor/detail/:id',
      page: DetailSeleksiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/transaksi/seleksi-vendor/tambah/:id',
      page: TambahSeleksiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/transaksi/seleksi-vendor/ubah/:id',
      page: UbahSeleksiVendor,
      hak: ['SUPA', 'PRO'],
    },

    // SELEKSI VENDOR ASET
    {
      exact: true,
      path: '/transaksi/seleksi-vendor-aset',
      page: SeleksiVendorAsetList,
      hak: ['SUPA', 'PRO', 'PRO_TRN_SVA'],
    },
    {
      exact: true,
      path: '/transaksi/seleksi-vendor-aset/permintaan-aset',
      page: PermintaanAsetSeleksiVendorAsetList,
      hak: ['SUPA', 'PRO', 'PRO_TRN_SVA'],
    },
    {
      exact: true,
      path: '/transaksi/seleksi-vendor-aset/tambah/:id',
      page: SeleksivendorAsetCreate,
      hak: ['SUPA', 'PRO', 'PRO_TRN_SVA'],
    },
    {
      exact: true,
      path: '/transaksi/seleksi-vendor/tambah/:id',
      page: TambahSeleksiVendor,
      hak: ['SUPA', 'PRO', 'PRO_TRN_SVA'],
    },
    {
      exact: true,
      path: '/transaksi/seleksi-vendor/ubah/:id',
      page: UbahSeleksiVendor,
      hak: ['SUPA', 'PRO', 'PRO_TRN_SVA '],
    },

    // PURCHASE ORDER
    {
      exact: true,
      path: '/transaksi/purchase-order',
      page: PurchaseOrder,
      hak: ['SUPA', 'PRO_TRN_PO'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order/vendor-terseleksi',
      page: SeleksiVendorPurchaseOrder,
      hak: ['SUPA', 'PRO_TRN_PO'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order/detail/:id',
      page: DetailPurchaseOrder,
      hak: ['SUPA', 'PRO_TRN_PO'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order/tambah/:id',
      page: TambahPurchaseOrder,
      hak: ['SUPA', 'PRO_TRN_PO'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order/ubah/:id',
      page: UbahPurchaseOrder,
      hak: ['SUPA', 'PRO_TRN_PO'],
    },

    // PURCHASE ORDER ASET
    {
      exact: true,
      path: '/transaksi/purchase-order-aset',
      page: PurchaseOrderAset,
      hak: ['SUPA', 'PRO_TRN_POA'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order-aset/vendor-terseleksi',
      page: SeleksiVendorPurchaseOrderAset,
      hak: ['SUPA', 'PRO_TRN_POA'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order-aset/detail/:id',
      page: DetailPurchaseOrderAset,
      hak: ['SUPA', 'PRO_TRN_POA'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order-aset/tambah/:id',
      page: TambahPurchaseOrderAset,
      hak: ['SUPA', 'PRO_TRN_POA'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order-aset/ubah/:id',
      page: UbahPurchaseOrderAset,
      hak: ['SUPA', 'PRO_TRN_POA'],
    },

    //CLOSE PURCHASE ORDER ASET
    {
      exact: true,
      path: '/transaksi/close-purchase-order-aset',
      page: ClosePurchaseOrderAsetList,
      hak: ['SUPA', 'PRO_TRN_CPA'],
    },

    {
      exact: true,
      path: '/transaksi/close-purchase-order-aset/purchase-order-aset',
      page: PurchaseOrderAsetList,
      hak: ['SUPA', 'PRO_TRN_CPA'],
    },

    {
      exact: true,
      path: '/transaksi/close-purchase-order-aset/purchase-order-aset/tambah/:id',
      page: ClosePurchaseOrderAsetCreate,
      hak: ['SUPA', 'PRO_TRN_CPA'],
    },

    {
      exact: true,
      path: '/transaksi/close-purchase-order-aset/detail/:id',
      page: ClosePurchaseOrderAsetDetail,
      hak: ['SUPA', 'PRO_TRN_CPA'],
    },

    //CLOSE PURCHASE ORDER
    {
      exact: true,
      path: '/transaksi/close-purchase-order',
      page: ClosePurchaseOrderList,
      hak: ['SUPA', 'PRO_TRN_CPO'],
    },

    {
      exact: true,
      path: '/transaksi/close-purchase-order/purchase-order',
      page: PurchaseOrderList,
      hak: ['SUPA', 'PRO_TRN_CPO'],
    },

    {
      exact: true,
      path: '/transaksi/close-purchase-order/purchase-order/tambah/:id',
      page: ClosePurchaseOrderCreate,
      hak: ['SUPA', 'PRO_TRN_CPO'],
    },

    {
      exact: true,
      path: '/transaksi/close-purchase-order/detail/:id',
      page: ClosePurchaseOrderDetail,
      hak: ['SUPA', 'PRO_TRN_CPO'],
    },

    // Permintaan Upah Produksi
    {
      exact: true,
      path: '/transaksi/transfer-upah-job-mix',
      page: TransferUpahJobMix,
      hak: ['SUPA', 'PRO_TRN_TUP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-upah-job-mix/list-permintaan-upah',
      page: ListPermintaanUpahJobMix,
      hak: ['SUPA', 'PRO_TRN_TUP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-upah-job-mix/tambah/:id',
      page: TambahTransferUpahJobMix,
      hak: ['SUPA', 'PRO_TRN_TUP'],
    },

    // Permintaan Subkon Produksi
    {
      exact: true,
      path: '/transaksi/transfer-subkon-job-mix',
      page: TransferSubkonJobMix,
      hak: ['SUPA', 'PRO_TRN_TSP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-subkon-job-mix/list-permintaan-subkon',
      page: ListPermintaanSubkonJobMix,
      hak: ['SUPA', 'PRO_TRN_TSP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-subkon-job-mix/tambah/:id',
      page: TambahTransferSubkonJobMix,
      hak: ['SUPA', 'PRO_TRN_TSP'],
    },

    // Transfer Upah Work Order
    {
      exact: true,
      path: '/transaksi/transfer-upah-work-order',
      page: ListTransferUpahWorkOrder,
      hak: ['SUPA', 'PRO_TRN_TWU'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-upah-work-order/list-permintaan-work-order',
      page: ListPermintaanUpahWorkOrder,
      hak: ['SUPA', 'PRO_TRN_TWU'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-upah-work-order/tambah/:id_work_order',
      page: TambahTransferUpahWorkOrder,
      hak: ['SUPA', 'PRO_TRN_TWU'],
    },

    {
      exact: true,
      path: '/laporan/purchase-order',
      page: LaporanPO,
      hak: ['SUPA', 'PRO_RPT_PO'],
    },
    {
      exact: true,
      path: '/laporan/purchase-order-vendor',
      page: LaporanPurchaseOrder,
      hak: ['SUPA', 'PRO_RPT_POV'],
    },

    //transfer subkon work order
    {
      exact: true,
      path: '/transaksi/transfer-subkon-work-order',
      page: ListTransferSubkonWorkOrder,
      hak: ['SUPA', 'PRO_TRN_TWS'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-subkon-work-order/list-permintaan-work-order',
      page: ListPermintaanSubkonWorkOrder,
      hak: ['SUPA', 'PRO_TRN_TWS'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-subkon-work-order/tambah/:id_work_order',
      page: TambahTransferSubkonWorkOrder,
      hak: ['SUPA', 'PRO_TRN_TWS'],
    },

    // Transfer Upah Produksi Aset
    {
      exact: true,
      path: '/transaksi/transfer-upah-produksi-aset',
      page: TransferUpahProduksiAsetList,
      hak: ['SUPA', 'PRO_TRN_TUPA'],
    },

    // Transfer SUbkon Produksi Aset
    {
      exact: true,
      path: '/transaksi/transfer-subkon-produksi-aset',
      page: TransferSubkonProduksiAsetList,
      hak: ['SUPA', 'PRO_TRN_TSPA'],
    },
  ],
};
